import React from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import Helmet from 'react-helmet';

import Layout from '../components/layout';

export default () => (
  <Layout>
    <Helmet title="Contact">
      <link rel="canonical" href={`https://wedeography.com/contact`} />
      <meta name="og:url" content={`https://wedeography.com/contact`} />
    </Helmet>
    <Container>
      <Row className="text-center">
        <Col>
          <h3>Like what you see?</h3>
          <h1>Let's have a chat!</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form data-netlify="true" name="contact" action="/success">
            <Input type="hidden" name="form-name" value="contact" />
            <FormGroup>
              <Input type="text" name="name" id="name" placeholder="Name" />
            </FormGroup>
            <FormGroup>
              <Input type="email" name="email" id="email" placeholder="Email" />
            </FormGroup>
            <FormGroup>
              <Input type="tel" name="telephone" id="telephone" placeholder="Phone/Whatsapp" />
            </FormGroup>
            <FormGroup>
              <Input type="text" name="telegram" id="telegram" placeholder="Telegram Username (optional)" />
            </FormGroup>
            <FormGroup>
              <Input type="textarea" name="text" id="message" placeholder="Your message" />
            </FormGroup>
            <Button block color="dark" className="rounded-0">Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  </Layout>
);
